<template>
  <Block title="采购申请执行情况">
    <chart-pie :colors="['#3776ED', '#37CBED', '#1ADBA5']" :types="['已采购', '进行中', '未采购']" class="chart" :dataList="dataList"></chart-pie>
  </Block>
</template>

<script>
import ChartPie from '../cmp/pie'
import Block from '../cmp/block'

export default {
  components: {
    Block,
    ChartPie
  },
  props: {
    theme: {
      type: String
    },
    data: {
      type: Array
    }
  },
  computed: {
    dataList () {
      let data = [{
        name: '已采购',
        value: 0
      },
      {
        name: '进行中',
        value: 0
      },
      {
        name: '未采购',
        value: 0
      }]
      this.data.forEach(v => {
        if (v.isUsed) {
          data[0].value += v.usedTotal || 0
        }
        if (v.isUsing) {
          data[1].value += v.usingTotal || 0
        }
        if (!v.isUsed && !v.isUsing) {
          data[2].value += v.total || 0
        }
      })
      return data
    }
  }
}
</script>

<style scoped lang="less">
.chart {
  width: 100%;
  height: 100%;
}
</style>
